import { getSubscriptionCadence } from 'components';
import { useCurrentCustomer, useCurrentCustomerSubscription } from 'imddata';
import { useFeature } from 'imdfeature';
import { useSubscriptionUpsell } from 'imdshared';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

type PromoType = 'free' | 'monthly' | 'none';

export const useSubsPromo = (): [boolean, MouseEventHandler, PromoType] => {
  const { entry: customer } = useCurrentCustomer();
  const subscription = useCurrentCustomerSubscription();
  const { open } = useSubscriptionUpsell();
  const [enabledPromo] = useFeature({ featureKey: 'promo-subs' });

  const cadence = subscription
    ? getSubscriptionCadence(subscription.subscriptionId)
    : null;

  const futureCadence = subscription?.renewToId
    ? getSubscriptionCadence(subscription.renewToId)
    : null;

  let type: PromoType = 'none';

  if (customer && enabledPromo) {
    if (
      subscription &&
      cadence === 'monthly' &&
      (!futureCadence || futureCadence === 'monthly')
    ) {
      type = 'monthly';
    }
    if (!subscription) {
      type = 'free';
    }
  }

  const handleClick = useCallback<MouseEventHandler>(() => {
    open({ analytics: { detail: 'promo-banner' } });
  }, []);

  return [type !== 'none', handleClick, type];
};
